import { Component } from '@angular/core';

@Component({
  selector: 'footer-cmp',
  templateUrl: './footerCmp.html',
  styleUrls: ['./footerCmp.scss']
})
export class FooterComponent {
  
}
