<header-cmp></header-cmp>
<div class="page-header-wrapper sydney-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="page-header-container">
          <h1 class="page-head" id="sydMyHealthDashboard">
            {{_titleService.title}}
          </h1>
          <div class="page-header-text-container">
            <div class="page-header-text">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="motif-theme dashboard-container">
  <div class="container">
    <div class="col-sm-3">
      <left-cont-cmp></left-cont-cmp>
    </div>

    <div class="col-sm-9 right-side-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<footer-cmp></footer-cmp>
