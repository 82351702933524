import { Injectable } from '@angular/core';
declare global {
const jsonLogic: any;
}
export interface IContentErrorResponse {
  errorCode?: string;
  data?: {};
  exceptions?: { type?: string; code?: string }[] | { code?: string };
  message?: { messageCode?: string };
  errors?: { code?: string }[];
}

/**
 * Content helper class can act as a global content store and register wcs json content agaisnt unique key.
 * Has support to override content for one or more groupIds, groupId_subGroupIds, or brand codes.
 */
@Injectable()
export class ContentHelper {
  protected _contentStore: { [name: string]: unknown } = {};

  /**
   *  Register/store json content for given key/name in local cache object.
   *
   *  @param name - content cache key/name
   *  @param content - json content string
   *  @return - returns registered content back.
   * */
  registerContent<T = unknown>(name: string, content: T): T {
    this._contentStore[name] = typeof content !== 'string' ? content : this.parseJSON(content);
    return content;
  }

  /**
   *  Allow parsing JSON string to JSON object without JS errors.
   *  @param jsonCont - JSON string to be parsed as JSON object
   *  @return - return JSON parsed object or null if cannot be parsed.
   */
  parseJSON(jsonCont: string, key?: string): object | undefined {
    if (typeof jsonCont !== 'string') {
      return undefined;
    }

    try {
      return JSON.parse(jsonCont);
    } catch (error) {
      console.error(error, key);
    }

    return undefined;
  }

  /**
   *  return cached JSON object for given key
   *  @param name - content key
   *  @return - return cached JSON object or undefined if not found.
   * */
  getContent<T = unknown>(name: string): T {
    return (this._contentStore[name] as unknown) as T;
  }

  /**
   *  Get override content
   *
   * Content can be overriden for list of groups, brands by providing override values in the JSON itself. We support two versions of
   * JSON override schemes.
   *
   * Verson 1 - see [Content Customization Framework](https://confluence.anthem.com/x/5Eu-Dg)
   *
   * ### Example
   * ```
   * {
   *   "default": {
   *     "title": Hello world!"
   *    },
   *   "270087, 270088": {
   *     "title": "Hello Verizon Member!"
   *   }
   * }
   * ```
   *
   * Verson 2 - see [Content as a Service Developer Guide](https://confluence.anthem.com/pages/viewpage.action?pageId=554183244). Enhanced
   * system based on a more flexible model using a list of [JsonLogic](http://jsonlogic.com) rules. The presence of the xOverrides property
   * indicates the JSON content is V2.
   *
   * Example:
   * ```
   * {
   *    "title": "Hello world!",
   *    "xOverrides": [
   *      {
   *        "xRule": {"in":[{"var":"groupId"}, ["270087", "270088"]]},
   *        "title": "Hello Verizon Member!"
   *      }
   *    ]
   * }
   * ```
   *
   *  @param name - cache content key
   *  @param brand - brand value
   *  @return - returns brand specific content merged with default content
   */
  getBrandContent<T = unknown>(name: string, subWidgetName: string = '', brand?: string, groupId?: string, subGroupId?: string): T {
    let content = this._contentStore[name] as { [k: string]: {} };

    if (subWidgetName && this._contentStore[name]) {
      content = (this._contentStore[name] as { [k: string]: unknown })[subWidgetName] as { [k: string]: {} };
    }

    content = content ? JSON.parse(JSON.stringify(content)) : content;

    if (!content) {
      // eslint-disable-next-line no-console
      console.warn(`no content found: ${name} ${subWidgetName}`);
      return (null as unknown) as T;
    }

    let t: { [k: string]: {} };
    if (content?.xOverrides) {
      t = this.overrideContentV2(content, brand, groupId, subGroupId);
    } else {
      if (content && !content.default) {
        return (content as unknown) as T;
      }

      t = this.overrideContent(content, { ...content.default }, brand);
      t = this.overrideContent(content, { ...t }, groupId);
      if (groupId && subGroupId) {
        t = this.overrideContent(content, { ...t }, `${groupId}_${subGroupId}`);
      }
    }

    return (t as unknown) as T;
  }

  /**
   *  Extract business specific error content key. if not available returns defaultErrorCode or 100.
   *  @param apiErrorResp - error data returned from api
   *  @param errorCodeContent - wcs error content section from content object
   *  @param defaultErrorCode - returns this error code if no error content is found with-in error content for api error result.
   */
  extractApiBusErrors(apiErrorResp: IContentErrorResponse = {}, errorCodeContent: { [k: string]: string }, defaultErrorCode: string, httpStatus: string = '') {
    let error: string | undefined = undefined;
    if (typeof apiErrorResp.data !== 'undefined' && (typeof apiErrorResp.errors === 'undefined' || typeof apiErrorResp.exceptions === 'undefined')) {
      apiErrorResp = apiErrorResp.data || {};
    }

    if (typeof apiErrorResp !== 'undefined' && typeof apiErrorResp.exceptions !== 'undefined' && apiErrorResp.exceptions !== null && Array.isArray(apiErrorResp.exceptions)) {
      apiErrorResp.exceptions.forEach((exception) => {
        if (
          typeof exception.type !== 'undefined' &&
          typeof exception.code !== 'undefined' &&
          (typeof errorCodeContent['ERR' + exception.code] !== 'undefined' || typeof errorCodeContent['err' + exception.code] !== 'undefined')
        ) {
          error = this.getErrorJsonProperty(errorCodeContent, exception.code);
        }
      });
    } else if (
      typeof apiErrorResp !== 'undefined' &&
      typeof apiErrorResp.exceptions !== 'undefined' &&
      apiErrorResp.exceptions !== null &&
      !Array.isArray(apiErrorResp.exceptions) &&
      typeof apiErrorResp.exceptions.code !== 'undefined' &&
      (typeof errorCodeContent['ERR' + apiErrorResp.exceptions.code] !== 'undefined' || typeof errorCodeContent['err' + apiErrorResp.exceptions.code] !== 'undefined')
    ) {
      error = this.getErrorJsonProperty(errorCodeContent, apiErrorResp.exceptions.code);
    } else if (
      typeof apiErrorResp !== 'undefined' &&
      typeof apiErrorResp.message !== 'undefined' &&
      typeof apiErrorResp.message.messageCode !== 'undefined' &&
      (typeof errorCodeContent['ERR' + apiErrorResp.message.messageCode] !== 'undefined' || typeof errorCodeContent['err' + apiErrorResp.message.messageCode] !== 'undefined')
    ) {
      error = this.getErrorJsonProperty(errorCodeContent, apiErrorResp.message.messageCode);
    } else if (typeof apiErrorResp !== 'undefined' && typeof apiErrorResp.errors !== 'undefined' && apiErrorResp.errors !== null && apiErrorResp.errors.length > 0) {
      apiErrorResp.errors.forEach((exception) => {
        if (typeof exception.code !== 'undefined') {
          error = this.getErrorJsonProperty(errorCodeContent, exception.code);
        }
      });
    } else if (
      typeof apiErrorResp !== 'undefined' &&
      typeof apiErrorResp.errorCode !== 'undefined' &&
      (typeof errorCodeContent['ERR' + apiErrorResp.errorCode] !== 'undefined' || typeof errorCodeContent['err' + apiErrorResp.errorCode] !== 'undefined')
    ) {
      error = this.getErrorJsonProperty(errorCodeContent, apiErrorResp.errorCode);
    }

    if (!error && httpStatus) {
      error = this.getErrorJsonProperty(errorCodeContent, httpStatus);
    }

    // if api error out with no specific error codes, show unknown error.
    if (!error || Object.keys(error).length === 0) {
      error = defaultErrorCode ? defaultErrorCode : '100';
    }

    return error;
  }

  protected getErrorJsonProperty(errorContent: { [k: string]: string }, errorCode: string): string {
    if (typeof errorContent['ERR' + errorCode] !== 'undefined') {
      return 'ERR' + errorCode;
    } else if (typeof errorContent['err' + errorCode] !== 'undefined') {
      return 'err' + errorCode;
    } else {
      return '';
    }
  }

  /** Override default content with defined override content for brand, group */
  protected overrideContent(mainContent: { [k: string]: {} }, currentContent: { [k: string]: {} }, contentKey?: string) {
    const contentKeyLower = (contentKey || '').toLowerCase();

    let foundKey;
    if (!contentKeyLower) {
      foundKey = undefined;
    } else if (mainContent[contentKeyLower]) {
      foundKey = contentKeyLower;
    } else {
      // If not able to quickly find a match above, we need to loop through all properties to find if our contentKey
      // is included in any of them. This will add a little bit more time but should not be significant. Also, this code
      // runs on the browser and does not add overhead to our server.
      // In my testing, even with a thousand override properties, it only takes
      // 2-3 ms to look up. In most real-world use cases where we have only a few overrides, there should be no significant
      // overhead
      for (const key of Object.keys(mainContent)) {
        const key2 = ',' + key.toLowerCase().replace(/\s+/g, '') + ',';
        if (key2.indexOf(',' + contentKeyLower + ',') >= 0) {
          foundKey = key;
          break;
        }
      }
    }

    if (!foundKey) {
      return currentContent;
    }

    const t1 = { ...currentContent };
    const c1 = mainContent[foundKey];

    if (c1) {
      $.extend(true, t1, c1);
    }

    return t1;
  }

  /** Override default content with content specific for brand or group/sub-groups
   * The overridden content is cached in xOverridden so we don't waste time running this code again
   * and again for the current user.
   *
   * This should not be a performance issue on the client. In a test on Chinh's laptop, this code
   * took about 1 ms to process a 50K JSON with 2 override rules.
   * */
  protected overrideContentV2(content: { [k: string]: unknown }, brand?: string, groupId?: string, subGroupId?: string) {
    if (content.xOverridden) {
      // xOverridden is the cached overridden content calculated earlier
      // For the same user this should not change in the same session so we cache it
      return content.xOverridden;
    }

    const t = JSON.parse(JSON.stringify(content));

    brand = (brand ?? '').toLowerCase();
    groupId = (groupId ?? '').toLowerCase();
    subGroupId = (subGroupId ?? '').toLowerCase();

    for (const i in content.xOverrides as { [k: string]: unknown }) {
      const rule = (content.xOverrides as { [k: string]: { xRule: unknown } })[i];

      let data = { brand: brand, groupId: groupId };
      let ruleResult = jsonLogic.apply(rule.xRule, data);
      if (ruleResult) {
        $.extend(true, t, rule);
      }

      // match group_subgroup
      data = { brand: brand, groupId: groupId + '_' + subGroupId };
      ruleResult = jsonLogic.apply(rule.xRule, data);
      if (ruleResult) {
        $.extend(true, t, rule);
      }
    }

    this.cleanMeta(t);
    content.xOverridden = t;

    return t;
  }

  /** Remove meta attributes (xRules, etc) from final content */
  private cleanMeta(obj: { [k: string]: unknown }) {
    for (const prop in obj) {
      if (prop === 'xOverrides' || prop === 'xRule') {
        delete obj[prop];
      } else if (typeof obj[prop] === 'object') {
        this.cleanMeta(obj[prop] as { [k: string]: unknown });
      }
    }
  }
}
