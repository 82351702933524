import {Component, OnInit} from '@angular/core';
import { TitleService } from '../services/titleSvc';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'right-cont-cmp',
  templateUrl: './rightContCmp.html',
  styleUrls: ['./rightContCmp.scss']
})
export class RightContainerComponent implements OnInit {
  title: string;
  constructor(private titleSvc: TitleService, private route: ActivatedRoute){}

  ngOnInit(): void {
    this.route.url.subscribe(() => {
      this.title = this.titleSvc.title;
    });
  }

}
