import { Injectable } from '@angular/core';
import { IAppConfig, IConfigAudit, IConfigFeatureToggle, IConfigHttpInterceptor, IConfigLogging, IConfigRestApi, IConfigWcs, IConfigWeb } from './../interfaces/iAppConfig';

/**
 *  App configuration settings
 */
@Injectable()
export class AppConfig implements IAppConfig {
  audit = (undefined as unknown) as IConfigAudit;
  restApi = (undefined as unknown) as IConfigRestApi;
  web = (undefined as unknown) as IConfigWeb;
  wcs = (undefined as unknown) as IConfigWcs;
  logging = (undefined as unknown) as IConfigLogging;
  httpInterceptor = (undefined as unknown) as IConfigHttpInterceptor;
  environment = (undefined as unknown) as string;
  featureToggle = (undefined as unknown) as IConfigFeatureToggle;
}
