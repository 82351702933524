<ul>
<ng-container *ngFor="let l of content.links;">
  <li *ngIf="!l.type" ><a [ngClass]="l.selected ? 'activemenu': '' " [routerLink]="l.url" [innerHTML]="l.label" [id]="l.id" [attr.data-analytics]="l.id"></a></li>
  <ng-container *ngIf="l.type === 'generic-lazy' && l.feature && l.featureSet">
      <a class="activemenu" [routerLink]="l.url" [id]="l.featureSet + l.id" [innerHTML]="l.label" [attr.data-analytics]="l.id"></a>
      
  </ng-container>
</ng-container>
    <li><a (click)="logout()">Logout</a></li>
</ul>

