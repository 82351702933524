import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreModule } from '../core/public_api';
import { FetchBackend } from './services/fetchBackend';
import { FetchClient } from './services/fetchClient';
import { HttpCacheHandler } from './services/httpCacheHandler';
import { HttpClient2 } from './services/httpClient2';
import { HttpRequestUtil } from './services/httpRequestUtil';
import { UrlHelper } from './services/urlHelper';

@NgModule({
  imports: [CoreModule],
  declarations: [],
  exports: []
})
export class Http2Module {
  static entryComponents: unknown = [];

  static forRoot(): ModuleWithProviders<Http2Module> {
    return {
      ngModule: Http2Module,
      providers: [UrlHelper, HttpClient2, HttpCacheHandler, HttpRequestUtil, FetchClient, FetchBackend]
    };
  }
}
