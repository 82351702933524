/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/naming-convention */

export interface IMetadata {
    featureFlags: string[];
    eligibility: IEligibility[];
    features: string[];
    mbrUid: string;
    userNm: string;
    webGuid: string;
    firstNm: string;
    lastNm: string;
    dob: string;
    genderCd: ISoaCode;
    registrationTypeCd: string;
    houseAccount: string;
    webChannelType: WebChannelType;
    hcid: string;
    brandCd: string;
    userRole: string;
    emulationInd: boolean;
    token: string;
    serverTime: string;
    showBillpayInd: boolean;
    showHistBillInd: boolean;
    reportingIndicators: string[];
    canEmulate: boolean;
    middleNm: string;
    username: string;
    uriEncodedMbrUid: string;
  }
  
  export interface IEligibility {
    covereageName: string;
    defaultContract: boolean;
    contractUid: string;
    hcId: string;
    relationshipCd: ISoaCode;
    planIndicators: string[];
    statusCd: ISoaCode;
    effectiveDt: string;
    mbrSequenceNbr: string;
    contractId: string;
    contractDataSource: string;
    brandCd: ISoaCode;
    underwritingStateCd: ISoaCode;
    sourceSystemId: string;
    groupNm: string;
    groupId: string;
    mbuCd: ISoaCode;
    alphaPrefix: string;
    subscriberId: string;
    residentialStateCd: ISoaCode;
    enrollmentTypeCd: ISoaCode;
    coverage: ICoverage[];
    omeEnabled: string;
    oeEnabled: string;
    renewalDt?: string;
    defaultCoverageTypeCd: ICoverageType[];
    defaultPlanNm: string;
    webChannelType: WebChannelType;
    terminationDt?: string;
    exchangeTypeCd: ISoaCode;
    userRole: string;
    customizations: {
      damImageUrl: string;
    };
    defaultCoverageType: ICoverageType[];
  
    planNm: string;
    coverageKey: string;
    employerCd: string;
  }
  
  interface WebChannelType {
    reason: string[];
    code: string;
    vivrElig: boolean;
  }
  
  export interface ICoverage {
    coverageKey: string;
    statusCd: ISoaCode;
    effectiveDt: string;
    terminationDt: string;
    product: IProduct[];
    coverageType: ICoverageType[];
  }
  
  export interface IProduct {
    productId: string;
    subgroupId: string;
    planNm: string;
    healthcareArgmtCd?: ISoaCode;
    jaaInd: string;
    coverageType: ICoverageType[];
    cplFilterInd?: string;
    pkgCd?: string;
    seniorProduct?: ISoaCode;
    contractUid?: string;
    isStartNewRxEligible?: boolean;
    isMedisysSourceSystem?: boolean;
  }
  
  export interface ICoverageType {
    coverageTypeCd: CoverageTypeCd;
    coverageProgram?: CoverageProgram[];
    vendor: {
      vendorId: string;
      vendorNm: string;
      carveInd?: string;
    };
    altCoverageKey: string;
  }
  
  interface CoverageProgram {
    vendorNm: string;
    vendorType?: string;
    carveInd?: string;
  }
  
  interface CoverageTypeCd {
    code: string;
    name: string;
    description?: string;
  }
  
  export interface ISoaCode {
    code: string;
    name?: string;
    description?: string;
  }
  