import { Inject, Injectable } from '@angular/core';
import { IWindow } from '../../core/public_api';
import { IUrl } from './../interfaces/iUrl';
import { IUrlParam } from './../interfaces/iUrlParam';

/**
 * Helper provider to handle various url level functions.
 */
@Injectable()
export class UrlHelper {
  private _urlParsingNode: HTMLAnchorElement;
  private _baseHref: string | null | undefined;

  constructor(@Inject('Window') private _window: IWindow) {
    this._urlParsingNode = this._window.document.createElement('a');
    this._baseHref = this._window.document.querySelector('base') ? this._window.document.querySelector('base')?.getAttribute('href') : '';
  }

  /**
   * Allow encoding chars on url.
   * @param val - url string
   */
  encodeUriSegment(val: string) {
    return this.encodeUriQuery(val, true).replace(/%26/gi, '&').replace(/%3D/gi, '=').replace(/%2B/gi, '+');
  }

  /**
   *  Allow encoding parameters in url
   *  @param val - url stirng
   *  @param pctEncodeSpaces - should encode spaces or not
   */
  encodeUriQuery(val: string, pctEncodeSpaces: boolean) {
    return encodeURIComponent(val)
      .replace(/%40/gi, '@')
      .replace(/%3A/gi, ':')
      .replace(/%24/g, '$')
      .replace(/%2C/gi, ',')
      .replace(/%20/g, pctEncodeSpaces ? '%20' : '+');
  }

  /**
   *  Add url parameter to url. include & or = depending on the url.
   * */
  getUrlParam(name: string, value: string, url: string) {
    let param = '';
    if (url.toLowerCase().indexOf(name.toLowerCase() + '=') < 0) {
      if (url.indexOf('?') < 0) {
        param = '?';
      } else {
        param = '&';
      }

      param = param + name + '=' + value;
    }

    return param;
  }

  /**
   *  allow resolving given url to various parts of the url.
   *  @param url - url stirng
   *  @return - resolve given url to IUrl structure
   */
  urlResolve(url: string): IUrl {
    let href = url;
    if (this._window.isIE) {
      // Normalize before parse.  Refer Implementation Notes on why this is
      // done in two steps on IE.
      this._urlParsingNode.setAttribute('href', href);
      href = this._urlParsingNode.href;
    }

    this._urlParsingNode.setAttribute('href', href);

    return {
      href: this._urlParsingNode.href,
      protocol: this._urlParsingNode.protocol ? this._urlParsingNode.protocol.replace(/:$/, '') : '',
      host: this._urlParsingNode.host,
      search: this._urlParsingNode.search ? this._urlParsingNode.search.replace(/^\?/, '') : '',
      hash: this._urlParsingNode.hash ? this._urlParsingNode.hash.replace(/^#/, '') : '',
      hostname: this._urlParsingNode.hostname,
      port: this._urlParsingNode.port,
      pathname: this._urlParsingNode.pathname.charAt(0) === '/' ? this._urlParsingNode.pathname : '/' + this._urlParsingNode.pathname,
      baseUri: this._urlParsingNode.baseURI
    };
  }

  /**
   *  returns parent domain name from browser location.href
   *  Ex: http://localhost:9000/member/test
   *
   *  returns http://localhost:9000 when location.href is above value
   */
  getParentDomainFromUrl(): string {
    const href = this._window.location.href.toLowerCase();
    const a = this.urlResolve(href);
    if (this._window.isIE) {
      let parentDomain = `${a.protocol}://${a.hostname}`;
      if (href.indexOf('localhost') === -1) {
        parentDomain += '/member/';
      } else {
        parentDomain += `:${a.port}/`;
      }
      return parentDomain;
    }
    return a.baseUri.toLowerCase();
  }

  /**
   *  returns cacheable url that is stripped with domain name and url parameters
   *  @param url - url stirng
   *  @param removeUrlParams - defaulted to true, this will remove the url params after ? char in url
   *  @return - returns cacheable url
   *
   *  Ex: http://localhost:9000/member/tcp/test?h=123
   *  transform above url to "tcp/test""
   */
  getCacheableUrl(url: string, removeUrlParams: boolean = true) {
    try {
      return url
        .toLowerCase()
        .replace(this.appBaseUrl, '')
        .replace(this._baseHref as string, '')
        .replace(removeUrlParams ? /\?(.|\s)*=(.|\s)*/gi : /no_Remove_url_params/, '')
        .trim();
    } catch (e) {
      return url;
    }
  }

  getAllUrlParameters(url: string = ''): Array<IUrlParam> {
    const params: Array<IUrlParam> = [];
    if (url.indexOf('?') >= 0) {
      url
        .split('?')[1]
        .split('&')
        .forEach((p: string) => {
          const s = p.split('=');
          params.push({
            name: s[0],
            value: s.length >= 2 ? s[1] : '',
            isQueryParam: true
          });
        });
    }

    return params;
  }

  getUrlParameter(name: string, url: string): string {
    let value: string | undefined = '';
    this.getAllUrlParameters(url).forEach((param) => {
      if (name === param.name) {
        value = param.value;
        return;
      }
    });

    return value;
  }

  get baseHref(): string {
    return this._baseHref as string;
  }

  get appBaseUrl(): string {
    return this._window.location.origin;
  }
}
