<div style="margin-left : 20px; margin-top : 20px">
    <form #loginForm="ngForm" name="loginForm" autocomplete="off" validate>

        <div class="form-group loginForm col-md-offset-3 col-md-6 col-sm-offset-3 col-sm-6 col-xs-offset-2 col-xs-8 col-xxs-12">
            <div class="form-item">
            Username:
            <input class="ant-text-input" #userName="ngModel" required id="userName" name="username" type="text" [(ngModel)]="username" />
            <br />
            <div class="error-msg" *ngIf="showValidations && userName.invalid">
                Username is required.
            </div>
            </div>
            <div class="form-item">
            Password:
            <input class="ant-text-input" #passWord="ngModel" required id="passWord" name="password" type="password" [(ngModel)]="password"/>
            <br />
            <div class="error-msg" *ngIf="showValidations && passWord.invalid">
                Password is required.
            </div>
            </div>
            <div class="form-item">
            Hostname:
            <input class="ant-text-input" #hostName="ngModel" required id="hostName" name="hostname" type="text" [(ngModel)]="hostname"/>
            <br />
            <div class="error-msg" *ngIf="showValidations && passWord.invalid">
                Hostname is required.
            </div>
            </div>
            <button class="btn btn-lg btn-primary" type="submit" (click)="submitClick()">Login</button>
            <button class="btn btn-lg btn-secondary" (click)="skipLogin()">Skip Login</button>
            <div *ngIf="loggedIn">
                Logged in!
            </div>
            <div class="error-msg" *ngIf="loginFailure">
                Failed to log in.
            </div>
        </div>
    </form>
</div>
