import { PlatformLocation } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IUrlParam,UrlHelper } from '../../http/public_api';
import { AppSession,IAppSession } from '../../main/public_api';
import * as $ from 'jquery';

/**
 *  Navigation helper utility
 *  Allows cosntructing valid url for routing and also does the routing.
 *  this needs to be used for any routing within the application instead of Router.navigateByUrl.
 */
@Injectable()
export class NavigationHelper {
  protected _baseHref: string = '';

  constructor(private _router: Router, private _platformLocation: PlatformLocation, private _urlHlpr: UrlHelper, @Inject(AppSession) private _appSession: IAppSession) {
    this._baseHref = $(document.getElementsByTagName('base')).attr('href') as string;
  }

  /**
   *  Wraps Router.navigateByUrl with safe url construction
   *  @param string url - internal url to route to
   */
  navigateTo(url: string): void;

  navigateTo(url: string, urlParams: Array<IUrlParam>): void;

  navigateTo(url: string, urlParams?: Array<IUrlParam>) {
    if (this._appSession.isVivrEligible && url.indexOf('vivr') < 0) {
      this._appSession.pageTitle = '';
      url = url.indexOf('?') > -1 ? url.replace('?', '/vivr?') : url + '/vivr';
    }
    if (urlParams) {
      urlParams.forEach((u) => {
        url = url + this._urlHlpr.getUrlParam(u.name, u.value as string, url);
      });
    }

    let finalUrl = this.getNavigationUrl(url).trimLeft();
    if (this._appSession.isIframeMode && this._appSession.iframeData && this._appSession.iframeData.urls && ((this._appSession.iframeData.urls as unknown) as { [k: string]: string })[finalUrl]) {
      finalUrl = ((this._appSession.iframeData.urls as unknown) as { [k: string]: string })[finalUrl];
      window.parent.postMessage(`tcp={"type":"navigation","url":"${finalUrl}"}`, '*');
    } else {
      this._router.navigateByUrl('/' + finalUrl);
    }
  }

  /**
   *  gets application baseHref value
   *  @return string - returns baseHref of the app
   */
  get baseHref(): string {
    return this._baseHref;
  }

  /**
   *  gets application current running environment base url
   *  @return string - returns application current base url
   */
  get appBaseUrl(): string {
    return ((this._platformLocation as unknown) as { _location: { origin: string } })._location.origin + this._baseHref;
  }

  /**
   *  checks given url is internal app url or not
   *  @param string url - url to validate
   *  @return boolean - returns if given url is valid or not
   */
  isAppUrl(url: string): boolean {
    url = this.getNavigationUrl(url);
    return !/^(javascript:void\(0\)|http|https|mailto|#focus:)/gi.test(url);
  }

  public getNavigationUrl(url: string): string {
    return url.replace(this.appBaseUrl, '');
  }
}
