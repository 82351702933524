import { Injectable } from '@angular/core';
import { AppConfig } from '../../main/public_api';

@Injectable()
export class ScriptLoader {
  private mapPromise: Promise<unknown>;

  constructor(private _appCon: AppConfig) {}

  loadMapsLibrary() {
    if (!this.mapPromise) {
      this.mapPromise = new Promise<void>((resolve, reject) => {
        window['__onBingLoaded'] = () => {
          resolve();
        };

        const body = document.getElementsByTagName('body')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = this._appCon.restApi.bingMaps.scriptUrl;
        /*script.onload = () => {
          resolve();
        };*/
        script.onerror = () => {
          reject();
        };
        body.appendChild(script);
      });
    }

    return this.mapPromise;
  }

  loadExternalScript(url: string, windowType?: string) {
    return new Promise<void>((res, rej) => {
      if (typeof ((window as unknown) as { [k: string]: unknown })[windowType as string] === 'undefined') {
        const body = document.getElementsByTagName('body')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = () => {
          /*TODO: this is wrong. this assigns string value to window object
          if (setOnWindowObject) {
            (window as any)[windowType] = windowType;
          }*/
          res();
        };
        script.onerror = () => {
          console.error('Error loading ' + windowType + ' script library');
          rej();
        };
        script.src = url;
        body.appendChild(script);
      } else {
        res();
      }
    });
  }

  // loadExternalScript(assetUrl: string, type: string) {
  //   return new Promise<void>((res, rej) => {
  //     const body: any = document.getElementsByTagName('body')[0];
  
  //     switch (type) {
  //       case 'SCRIPT':
  //         {
  //           const script: any = document.createElement('script');
  //           script.type = 'text/javascript';
  //           script.onload = () => {
  //             res();
  //           };
  //           script.onerror = () => {
  //             console.error('Error loading d3 script library');
  //             rej();
  //           };
  //           script.src = assetUrl;
  //           body.appendChild(script);
  
  //           break;
  //         }
  //       case 'CSS':
  //         {
  //           const link = document.createElement('link');
  //           link.rel = 'stylesheet';
  //           link.type = 'text/css';
  //           link.onload = () => {
  //             res();
  //           };
  //           link.onerror = () => {
  //             console.error('Error loading d3 css library');
  //             rej();
  //           };
  //           link.href = assetUrl;
  //           body.appendChild(link);
  
  //           break;
  //         }
  //       default:
  //         break;
  //     }
  //   });
  // }


}
