import { IEntryComponent } from './../interfaces/iEntryCmp';
import { AppBootUtil } from './../services/appBootUtil';

/**
 * This decorator allows you to register your component class in global cache that can be used to dynamically create components through WCS widget definition.
 * Only register components that are used through WCS widget loader definition, do not decorate child components that are not referenced like below.
 * ```html
 * <div data-widget-name="ComponentClassName" ></div>
 * ```
 * @param annotation
 * Usage:
 * ```
 * @EntryComponent({
 *   name: 'DashboardComponent',
 *   type: DashboardComponent
 * })
 * @Component()
 * export class DashboardComponent {}
 * data-widget-name should match the value given for name property and will match the type and create that component dynamically.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function EntryComponent(annotation: IEntryComponent) {
  return (target: Function) => {
    AppBootUtil.registerEntryComponent(annotation.name, annotation.type, annotation.priority);
    //let metadata = new Component(annotation);
    //Reflect.defineMetadata('annotations', [ metadata ], target);
  };
}
