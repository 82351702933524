import {  Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class loginApi  {
  constructor(private _httpClient: HttpClient) {
  }

  loginApi(username:string) {
      const url = 'https://api.ci1.awse1.rcp.anthem.com/member/secure/api/tcp/membership/member/web/bootstrap?cached=false';
      return this._httpClient.get(url, {
        headers: {
          'SMUNIVERSALID':username
        }
      }).subscribe(
        (data) => {
          if (data.hasOwnProperty('token')) {
            sessionStorage.setItem('token', data['token']);
          }
          return data;
        },
        (err: HttpErrorResponse) => {
            return err;
        }
    );    
  }
}