import { HttpClient2 } from './httpClient2';
import { Injectable } from '@angular/core';

/**
 *  Base API wrapper class. This needs to be extended for any other API wrapper.
 */
@Injectable()
export class BaseApi2 {
  constructor(protected _httpClient: HttpClient2) {}
}
