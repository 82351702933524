import { ModuleWithProviders, NgModule } from '@angular/core';
import { AttributeHelper } from './services/attributeHlpr';
import { Cache } from './services/cache';
import { ComponentHelper } from './services/componentHelper';
import { ContentHelper } from './services/contentHelper';
import { DynamicHtmlUtility } from './services/dynamicHtmlUtil';
import { ElementHelper } from './services/elementHelper';
import { EventHandler } from './services/eventHandler';

@NgModule({
  imports: [],
  declarations: [],
  exports: []
})
export class CoreModule {
  static entryComponents: unknown = [];

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        Cache,
        EventHandler,
        ContentHelper,
        ComponentHelper,
        ElementHelper,
        DynamicHtmlUtility,
        AttributeHelper
      ]
    };
  }
}
