import { ISelectorComponent } from './../interfaces/iSelectorCmp';
import { AppBootUtil } from './../services/appBootUtil';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function SelectorComponent(annotation: ISelectorComponent) {
  return (target: Function) => {
    AppBootUtil.registerSelectorComponent(annotation.selector, annotation.component, annotation.priority, annotation.inputs, (annotation.replaceNode as unknown) as boolean);
    //let metadata = new Component(annotation);
    //Reflect.defineMetadata('annotations', [ metadata ], target);
  };
}
