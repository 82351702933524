import { Injectable } from '@angular/core';

/**
 *  Cache provider allows creating adding/getting cache items
 */
@Injectable()
export class Cache {
  protected _cache: { [k: string]: unknown };
  protected _size: number = 0;

  constructor() {
    this._cache = {};
  }

  /**
   *  add cache item
   *  @param key - cache item key
   *  @param value - cahce item
   *  @return - return added cache item
   */
  put(key: string, value: unknown) {
    if (!value) {
      return;
    }

    if (!this._cache[key]) {
      this._size++;
    }

    this._cache[key] = value;
  }

  /**
   *  Allow getting item from Cache
   *  @param key - cached item key
   *  @return - cached item
   */
  get<T = unknown>(key: string): T {
    return ((this._cache as unknown) as { [k: string]: T })[key];
  }

  /**
   *  Allow removing single cache item with specific key
   *  @param key - cache item key
   *  @return  - return true if cache item is found and successfully removed. or false.
   */
  remove(key: string): boolean {
    if (!this._cache[key]) {
      return false;
    }

    delete this._cache[key];
    this._size--;
    return true;
  }

  removeByRegexString(keyRegexStr: string) {
    const regex = new RegExp(keyRegexStr, 'ig');
    for (const key in this._cache) {
      if (regex.test(key)) {
        this.remove(key);
      }
    }
  }

  /**
   *  Removes all the cached items cached.
   */
  removeAll() {
    this._cache = {};
    this._size = 0;
  }
}
