import { Injectable } from '@angular/core';
import { ResponseType } from '../enums/responseTypeEnum';
import { IHttpRequest } from '../interfaces/iHttpRequest';
import { IUrlParam } from '../interfaces/iUrlParam';
import { UrlHelper } from './urlHelper';

@Injectable()
export class HttpRequestUtil {
  constructor(private _urlHelper: UrlHelper) {}

  runRequestInterceptors(request: IHttpRequest): IHttpRequest {
    request.requestInterceptors?.forEach((interceptor) => {
      request = interceptor.transform(request);
    });

    return request;
  }

  trimSpaces(request: IHttpRequest): IHttpRequest {
    if (request.trimSpaces === true) {
      (request.urlParams || []).forEach((p) => {
        p.value = p.value?.trim();
      });

      if (!request.isMultiPartRequest && request.data) {
        try {
          request.data = JSON.parse(JSON.stringify(request.data).replace(/"\s+|\s+"/g, '"'));
        } catch (e) {
          console.error(`error trimming spaces: ${e}`);
        }
      }
    }

    return request;
  }

  getHttpRequest(requestOptions: IHttpRequest) {
    requestOptions = this.runRequestInterceptors(requestOptions);
    requestOptions = this.trimSpaces(requestOptions);
    requestOptions = this.addCustomHeader(requestOptions, 'meta-flow', requestOptions.metaFlow);
    requestOptions = this.addCustomHeader(requestOptions, 'meta-brandcd', requestOptions.metaBrandCd);
    requestOptions = this.addCustomHeader(requestOptions, 'meta-locale', requestOptions.metaLocale);
    requestOptions = this.addCustomHeader(requestOptions, 'meta-consumer', requestOptions.metaConsumer);

    const finalRequestUrl = this.getUrl(requestOptions.url, requestOptions.urlParams);
    requestOptions.url = finalRequestUrl;
    requestOptions.finalUrl = this.getUrlWithQueryParams(finalRequestUrl, requestOptions.urlParams);
    requestOptions.responseType = requestOptions.responseType || ResponseType.Text;
    requestOptions.withCredentials = requestOptions.withCredentials || false;
    requestOptions.data = this.getHttpBody(requestOptions);
    requestOptions.body = requestOptions.data;

    return requestOptions;
  }

  getUrl(url: string, urlParams: Array<IUrlParam> = []): string {
    for (const param of urlParams) {
      if (!param.isQueryParam && !new RegExp('^\\d+$').test(param.name) && param.value && new RegExp('(^|[^\\\\]):' + param.name + '(\\W|$)').test(url)) {
        const encVal = param.noEncode ? param.value : this._urlHelper.encodeUriSegment(param.value);
        url = url.replace(new RegExp(':' + param.name + '(\\W|$)', 'g'), (match, p1) => {
          return encVal + p1;
        });
      }
    }

    return url;
  }

  private addCustomHeader(request: IHttpRequest, name: string, value?: string) {
    if (value) {
      request.headers = request.headers || [];
      const hasHeader = request.headers.some((header) => {
        return header.name === name;
      });

      if (!hasHeader) {
        request.headers.push({
          name,
          value
        });
      }
    }

    return request;
  }

  private getUrlWithQueryParams(url: string, urlParams?: IUrlParam[]): string {
    if (urlParams) {
      for (const up of urlParams.filter((u) => {
        return u.isQueryParam;
      })) {
        const v = up.value;
        const k = up.name;
        if (typeof v !== 'undefined' && v !== null) {
          url += this._urlHelper.getUrlParam(k, v, url);
        }
      }
    }

    return url;
  }

  private getHttpBody(requestOptions: IHttpRequest) {
    if (requestOptions.data) {
      return requestOptions.isMultiPartRequest ? requestOptions.data : JSON.stringify(requestOptions.data);
    }

    return null;
  }
}
