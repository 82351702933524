import { Injectable } from '@angular/core';

/**
 * HTML element attribute helper.
 * allows reading attributes from given element and store them and clear them accordingly from in-memory
 */
@Injectable()
export class AttributeHelper {
  getAttributes(element: HTMLElement): { [attrKey: string]: string } {
    const attrs: { [attrKey: string]: string } = {};
    for (let index = 0; index < element.attributes.length; index++) {
      const a = element.attributes[index];
      if (a.name.indexOf('aaf') < 0 && a.name.indexOf('widget') < 0) {
        attrs[a.name] = a.value;
      }
    }

    return attrs;
  }
}
