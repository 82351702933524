import {Component, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { TitleService } from '../services/titleSvc';
import {MHCSDKService} from "@mhc/sdk";
import {Subscription} from "rxjs";
@Component({
  selector: 'dashboard-cmp',
  templateUrl: './dashboardCmp.html',
  styleUrls: ['./dashboard.scss']
})
export class DashboardComponent implements OnDestroy {
  private mhcSubscription: Subscription;

  constructor(private _router: Router,public _titleService: TitleService, private mhcSDKService: MHCSDKService){
    this.mhcSubscription = this.mhcSDKService.events.subscribe((event) => {
      if (event.name === 'MHCError') {
        if (event.errorCode === 'authenticationRequired') {
        } else {
          alert(event.errorMessage);
        }
      } else if (event.name === 'MHCTransferUser' || event.name === 'MHCChangeLocation') {
        alert(`${event.name}: ${event.data}`);
      }
      if (
          event.name === "MHCTransferUser" &&
          event.data === "NAVIGATE_REWARDS_HISTORY"
      ) {
        this._router.navigateByUrl("dashboard/my-health-dashboard");
      } else if (event.name === 'MHCChangeLocation') {
        this.mhcSDKService.navigateToPath(event.data);
      }
    });
    this.mhcSDKService.setOptions({showBackground: false})

  }

  ngOnDestroy() {
    this.mhcSubscription.unsubscribe();
    this.mhcSubscription = undefined;
  }
}
