import { Injectable } from '@angular/core';
import { ContentHelper } from '../../core/public_api';
import { Observable, OperatorFunction, Subscription, throwError } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { IHttpHeader } from '../interfaces/iHttpHeader';
import { ResponseType } from './../enums/responseTypeEnum';
import { IHttpClient } from './../interfaces/iHttpClient';
import { IHttpRequest } from './../interfaces/iHttpRequest';
import { IHttpRequestInterceptor } from './../interfaces/iHttpRequestInterceptor';
import { IHttpResponse, IRawHttpResponse } from './../interfaces/iHttpResponse';
import { IHttpResponseInterceptor } from './../interfaces/iHttpResponseInterceptor';
import { FetchClient } from './fetchClient';
import { HttpCacheHandler } from './httpCacheHandler';
import { HttpRequestUtil } from './httpRequestUtil';

/**
 *  Custom HTTP Client to be used for any http calls from the application.
 *  This uses Http provider from Angular framework.
 *  and allows modifying request headers and request data and also handle response using interceptors.
 */
@Injectable()
export class HttpClient2 implements IHttpClient {
  public enabled = true;
  protected cancellableRequests: Array<{ unsubscribe: () => void; isClosed: () => boolean }> = [];
  protected _respInterceptors: Array<IHttpResponseInterceptor> = [];
  protected _reqInterceptors: Array<IHttpRequestInterceptor> = [];
  protected _currentSubscribers: { [k: string]: Observable<IRawHttpResponse> } = {};
  protected _maxRequests: { [k: string]: number } = {};

  constructor(protected _http2: FetchClient, protected _content: ContentHelper, protected _cacheHandler: HttpCacheHandler, protected _reqUtil: HttpRequestUtil) {}

  /**
   *  allows making HTTP request with given HTTP method and other feature.
   *  @param requestOptions - request opetions for making the Request.
   *  @return - returned promise which can be used to handle http response success/fail.
   */
  async request<T = unknown>(originalRequestOptions: IHttpRequest, preparsedReqObject?: IHttpRequest) {
    //allow disabling api calls
    if (!this.enabled) {
      return Promise.reject({
        status: 500,
        data: {}
      });
    }

    if (!preparsedReqObject) {
      preparsedReqObject = this.getRequestObject(originalRequestOptions);
    }

    //do not add any other code above or below this function.
    return this.getRequestPromise(preparsedReqObject) as Promise<IHttpResponse<T>>;
  }

  getRequestObject(requestOptions: IHttpRequest) {
    requestOptions = this.addInterceptors(requestOptions);
    const reqObj = this._reqUtil.getHttpRequest(requestOptions);
    return reqObj;
  }

  /**
   *  Allows canceling any pending requests made with this provider.
   *  This provider will keep all the requests made in an internal store.
   */
  cancelPendingRequests() {
    for (let i = this.cancellableRequests.length - 1; i >= 0; i--) {
      this.cancellableRequests[i].unsubscribe();
      this.cancellableRequests.splice(i, 1);
    }
  }

  waitOnCancellableRequests() {
    return new Promise<void>((resolve) => {
      const intervalRef = setInterval(() => {
        let allClosed = true;
        for (let i = this.cancellableRequests.length - 1; i >= 0; i--) {
          if (!this.cancellableRequests[i].isClosed()) {
            allClosed = false;
          }
        }

        if (allClosed) {
          if (intervalRef) {
            clearInterval(intervalRef);
          }
          return resolve();
        }
      }, 100);

      setTimeout(() => {
        if (intervalRef) {
          clearInterval(intervalRef);
          resolve();
        }
      }, 30000);
    });
  }

  /**
   *  Add/Register global request interceptor to be run on all HTTP requests from this provider.
   *  @param interceptor - request internceptor
   */
  addRequestInterceptor(interceptor: IHttpRequestInterceptor) {
    this._reqInterceptors.push(interceptor);
  }

  /**
   *  Add/Register global response interceptor to be run on all HTTP responses from this provider.
   *  @param IHttpResponseInterceptor interceptor - response internceptor
   */
  addResponseInterceptor(interceptor: IHttpResponseInterceptor) {
    this._respInterceptors.push(interceptor);
  }

  getRequestPromise(requestOptions: IHttpRequest) {
    const cachedResp = this._cacheHandler.lookupCache(requestOptions);
    if (cachedResp) {
      return Promise.resolve(cachedResp);
    }

    const p = new Promise<IHttpResponse>((resolve, reject) => {
      try {
        let httpObservable: Observable<IRawHttpResponse>;
        if (requestOptions.reusable && this._currentSubscribers[requestOptions.finalUrl as string]) {
          httpObservable = this._currentSubscribers[requestOptions.finalUrl as string];
        } else {
          httpObservable = this.internalRequest(requestOptions) as Observable<IRawHttpResponse>;
          if (requestOptions.reusable) {
            this._currentSubscribers[requestOptions.finalUrl as string] = httpObservable;
          }
        }

        let timeoutTimer: number | undefined;
        const subscriber = httpObservable.subscribe(
          (rawResp) => {
            //type=0 returns only when running unit tests which uses angular httpbackend
            if (rawResp && ((rawResp as unknown) as { type: number }).type === 0) {
              return;
            }

            try {
              if (requestOptions.maxRequests) {
                this._maxRequests[requestOptions.finalUrl as string] = (this._maxRequests[requestOptions.finalUrl as string] || 0) + 1;
              }

              rawResp = this.runResponseInterceptors(rawResp, requestOptions, requestOptions.responseInterceptors);
            } catch (e) {
              console.error(e);
            }

            if (typeof timeoutTimer !== 'undefined') {
              clearTimeout(timeoutTimer);
              timeoutTimer = undefined;
            }
            this.clearReusableRequest(requestOptions.finalUrl as string);

            const finalResp = this.getHttpResponse(rawResp, requestOptions);
            this._cacheHandler.storeInMemoryCache(requestOptions, finalResp);
            resolve(finalResp);
          },
          (rawErr: IRawHttpResponse) => {
            try {
              rawErr.body = rawErr.body;
              rawErr = this.runResponseInterceptors(rawErr, requestOptions, requestOptions.responseInterceptors);

              if (requestOptions.maxRequests) {
                this._maxRequests[requestOptions.finalUrl as string] = (this._maxRequests[requestOptions.finalUrl as string] || 0) + 1;
              }
            } catch (e) {
              console.error(e);
            }

            if (typeof timeoutTimer !== 'undefined') {
              clearTimeout(timeoutTimer);
              timeoutTimer = undefined;
            }
            this.clearReusableRequest(requestOptions.finalUrl as string);
            reject(this.getHttpResponse(rawErr, requestOptions));
          }
        );

        if (requestOptions.timeout && requestOptions.method === 'GET' && !subscriber.closed) {
          timeoutTimer = window.setTimeout(() => {
            this.unsubscribeRequest(subscriber, reject);
            timeoutTimer = undefined;
          }, requestOptions.timeout);
        }

        if (requestOptions.cancellable) {
          this.cancellableRequests.push({
            unsubscribe: () => {
              this.unsubscribeRequest(subscriber, reject);
              if (timeoutTimer) {
                clearTimeout(timeoutTimer);
                timeoutTimer = undefined;
              }
            },
            isClosed: () => {
              return subscriber.closed;
            }
          });
        }
      } catch (error) {
        console.error(error);
        reject({ status: -1, data: undefined });
      }
    });

    return p;
  }

  protected addInterceptors(originalHttpRequest: IHttpRequest) {
    originalHttpRequest.responseInterceptors = (originalHttpRequest.responseInterceptors || []).concat(this._respInterceptors);
    originalHttpRequest.requestInterceptors = (originalHttpRequest.requestInterceptors || []).concat(this._reqInterceptors);

    return originalHttpRequest;
  }

  protected clearReusableRequest(urlWithParams: string) {
    if (this._currentSubscribers[urlWithParams]) {
      delete this._currentSubscribers[urlWithParams];
    }
  }

  protected runResponseInterceptors(response: IRawHttpResponse, request: IHttpRequest, interceptors?: Array<IHttpResponseInterceptor>): IRawHttpResponse {
    (interceptors || []).forEach((interceptor) => {
      response = interceptor.transform(response, request);
    });

    return response;
  }

  protected unsubscribeRequest(subscription: Subscription, reject: (t: IHttpResponse) => void) {
    if (!subscription.closed) {
      subscription.unsubscribe();
      reject(this.getHttpResponse((null as unknown) as IRawHttpResponse, (null as unknown) as IHttpRequest));
    }
  }

  protected internalRequest(requestOptions: IHttpRequest) {
    if (requestOptions.maxRequests && (this._maxRequests[requestOptions.finalUrl as string] || 0) >= requestOptions.maxRequests) {
      return throwError({
        status: 429,
        statusText: 'Too many requests. blocked from UI.',
        url: requestOptions.finalUrl,
        headers: new Headers({}),
        error: '{}'
      });
    }

    let o2: Observable<IRawHttpResponse | unknown>;
    if (requestOptions.responseType !== ResponseType.Jsonp) {
      o2 = this._http2.request(requestOptions);
    } else {
      o2 = this._http2.jsonp(requestOptions.finalUrl as string, 'jsonp').pipe(
        map((result) => {
          try {
            if (result && (result as { type: number }).type === 0) {
              return result;
            }

            return { body: JSON.stringify(result || {}), headers: new Headers({}), status: 200, statusText: 'ok', url: requestOptions.finalUrl };
          } catch (error) {
            console.error(error);
          }

          return { body: 'error', headers: new Headers({}), status: -1, statusText: 'error', url: requestOptions.finalUrl };
        })
      );
    }
    if (requestOptions.reusable) {
      return o2.pipe((share() as unknown) as OperatorFunction<unknown, IRawHttpResponse>);
    }
    return o2;
  }

  protected getHttpResponse(rawResp: IRawHttpResponse, requestOptions: IHttpRequest): IHttpResponse {
    const responseHeaders: Array<IHttpHeader> = [];
    let hasNewData = false;
    if (!rawResp) {
      return {
        status: -1,
        data: {},
        headers: responseHeaders,
        hasNewData: hasNewData
      };
    }

    let data: unknown = {};
    try {
      if (rawResp.headers) {
        rawResp.headers.forEach((headerValue: string, headerName: string) => {
          responseHeaders.push({
            name: headerName,
            value: headerValue
          });
          if (headerName === 'X-Updated') {
            hasNewData = true;
          }
        });
      }

      if (
        (rawResp.headers && rawResp.headers.get && rawResp.headers.get('Content-Type') && /(html|xml|javascript|css|text)/gi.test(rawResp.headers.get('Content-Type') as string)) ||
        requestOptions.responseType === ResponseType.Blob
      ) {
        data = rawResp.body;
      } else {
        if (rawResp.body) {
          data = this._content.parseJSON(rawResp.body as string, rawResp.url);
          if (requestOptions.isGraphql) {
            data = (data as { data: unknown }).data;
          }
        } else {
          data = {};
        }
      }
    } catch (error) {
      console.error(error);
      data = {};
    }

    return {
      status: rawResp.status,
      data: data,
      headers: responseHeaders,
      hasNewData: hasNewData,
      urlWithParams: rawResp.url
    };
  }
}
