import { Component, OnInit } from '@angular/core';
import { MHCSDKService } from '@mhc/sdk';
import {Subject, Subscription} from "rxjs";
import {AuthService} from "@mhc/sdk/src/lib/core-services";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mhctestharness';

  private mhcSubscription: Subscription;

  constructor(private mhcSDKService: MHCSDKService, private auth: AuthService, private router: Router) {
    this.auth.isAuthenticated().subscribe((authenticated) => {
      if (authenticated) {
        const authenticated$ = this.mhcSDKService._authenticated as Subject<boolean>;
        authenticated$.next(true);
      }
    });
  }

}
