import { Component } from '@angular/core';

@Component({
  selector: 'header-cmp',
  templateUrl: './headerCmp.html',
  styleUrls: ['./headerCmp.scss']
})
export class HeaderComponent {
  
}
