import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ScriptLoader } from 'src/libs/shared/services/scriptLoader';
import { TitleService } from '../services/titleSvc';

@Component({
  selector: 'rewards-cmp',
  templateUrl: './rewardsCmp.html',
  styleUrls: ['./rewardsCmp.scss']
})
export class RewardsComponent {

  @ViewChild('mhcContainerElement', { static: false }) public mhcElement: ElementRef;
  authToken: string = 'f532bd5f-fc1b-4344-ba69-072e10e5b0c';
  el: HTMLElement;
  constructor(public _titleSvc : TitleService, private _scriptLoader: ScriptLoader){}

  eventHandler(e){
    console.log('Event From MHC ', e);
  }

  

}
