import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ResponseType } from '../enums/responseTypeEnum';
import { IHttpHeader } from '../interfaces/iHttpHeader';
import { IHttpRequest } from '../interfaces/iHttpRequest';
import { FetchBackend } from './fetchBackend';

@Injectable()
export class FetchClient {
  constructor(private _backend: FetchBackend) {}

  request(req: IHttpRequest) {
    return this._backend.handle(req);
  }

  get(url: string, options: { headers?: IHttpHeader[] }) {
    return this._backend.handle({
      url,
      method: 'GET',
      headers: options.headers,
      responseType: ResponseType.Blob,
      urlParams: [],
      finalUrl: url
    });
  }

  jsonp(url: string, callbackParam: string) {
    return this._backend
      .handle({
        url,
        method: 'JSONP',
        responseType: ResponseType.Json,
        urlParams: [{ name: callbackParam, value: 'JSONP_CALLBACK', isQueryParam: true }],
        headers: [],
        finalUrl: url
      })
      .pipe(
        map((val) => {
          if ((val as { body: unknown }).body) {
            return (val as { body: unknown }).body;
          }

          return val;
        })
      );
  }
}
