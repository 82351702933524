/**
 * In javascript closures throwing new Error will stop garbage collection for all the values used in closure (until stack property is reference in error obj).
 * this is a safe get new error function that access stack property to trigger garbage collection.
 */
export function getSafeError(message: string): Error {
  const error = new Error(message);
  // In V8, Error objects keep the closure scope chain alive until the
  // err.stack property is accessed.
  if (error.stack === undefined) {
    // IE sets the stack only if error is thrown
    try {
      throw error;
    } catch {
      //unhandle
    }
  }
  return error;
}
