import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, switchMap } from 'rxjs/operators';
import { ResponseType } from '../enums/responseTypeEnum';
import { IHttpHeader } from '../interfaces/iHttpHeader';
import { IHttpRequest } from '../interfaces/iHttpRequest';
import { IRawHttpResponse } from '../interfaces/iHttpResponse';

@Injectable()
export class FetchBackend {
  handle(req: IHttpRequest): Observable<unknown> {
    try {
      if (req.method === 'JSONP') {
        return this.handleJsonp(req);
      }

      return fromFetch(req.finalUrl as string, this.transformRequest(req)).pipe(
        switchMap((response) => {
          return this.getResponse(response, req);
        }),
        catchError((err) => {
          // Network or other error, handle appropriately
          return throwError({
            body: err.body || JSON.stringify({ error: err.stack || '' }),
            status: err.status || -1,
            statusText: err.statusText || '',
            url: err.url || req.url,
            headers: err.headers || new Headers({})
          });
        })
      );
    } catch (error) {
      return throwError({ body: JSON.stringify({ error: error.stack }), status: -1, statusText: '', url: req.url, headers: new Headers() });
    }
  }

  private handleJsonp(req: IHttpRequest) {
    const name = `_jsonp_${Date.now()}`;
    const jsonpParam = req.urlParams?.filter((p) => {
      return p.value === 'JSONP_CALLBACK';
    });

    if (!jsonpParam?.length) {
      return throwError({ error: 'missing jsonp param', status: -1, statusText: '', url: req.finalUrl, headers: new Headers() });
    }

    const url = req.finalUrl + `${(req.finalUrl?.indexOf('?') as number) >= 0 ? '&' : '?'}${jsonpParam[0].name}=${name}`;

    return from(
      new Promise((res, rej) => {
        const script = document.createElement('script');

        script.src = url;
        ((window as unknown) as { [k: string]: (j: string) => void })[name] = (json: string) => {
          res(json);
          document.body.removeChild(script);
          delete ((window as unknown) as { [k: string]: unknown })[name];
        };
        script.onerror = () => {
          document.body.removeChild(script);
          rej();
        };
        document.body.appendChild(script);
      })
    );
  }

  private async getResponse(response: Response, request: IHttpRequest): Promise<IRawHttpResponse | Blob> {
    let body: string | Blob;
    if (request.responseType === ResponseType.Blob) {
      body = await response.blob();
    } else {
      body = await response.text();
    }
    if (response.ok) {
      const resp = {
        body,
        headers: this.transformHeaders(response.headers),
        status: response.status,
        statusText: response.statusText,
        url: response.url
      };
      return Promise.resolve(resp);
    } else {
      const errResp = {
        body,
        headers: this.transformHeaders(response.headers),
        status: response.status,
        statusText: response.statusText,
        url: response.url
      };
      return Promise.reject(errResp);
    }
  }

  private transformRequest(req: IHttpRequest): RequestInit {
    const headers = req.headers;
    const r: RequestInit = {
      method: req.method,
      credentials: req.withCredentials ? 'include' : undefined,
      body: (undefined as unknown) as BodyInit | null,
      headers: (undefined as unknown) as Headers
    };

    if (req.data) {
      r.body = (req.data as unknown) as BodyInit | null;
      const contHeader = headers?.filter((h) => {
        return h.name.toLowerCase() === 'content-type';
      })[0];
      if (!contHeader && typeof req.data === 'string') {
        headers?.push({ name: 'content-type', value: 'application/json' });
      }
    }

    r.headers = this.getHttpHeaders(headers);

    return r;
  }

  private transformHeaders(headers: Headers): Headers {
    return headers || new Headers({});
  }

  private getHttpHeaders(headers?: IHttpHeader[]): Headers {
    if (headers) {
      const h: { [k: string]: string } = {};
      for (const header of headers) {
        const k = header.name;
        const v = header.value;
        if (typeof v !== 'undefined' && v !== null) {
          h[k] = v;
        }
      }

      return new Headers(h);
    }

    return new Headers({});
  }
}
