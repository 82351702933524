import { EventEmitter, Injectable } from '@angular/core';
import { IEvent } from './../interfaces/iEvent';

/**
 *  Helper provider to handle events globally.
 *  This allows creating internal event cache that will be used to manage global events.
 *  other components in the application can get specific event and listen/emit on those events.
 */
@Injectable()
export class EventHandler {
  private _events: Array<IEvent> = [];

  /**
   *  Allow getting cached EventEmitter for given key
   *  @param name - cache key to be used to hold the created event
   *  @return - returns created or cached EventEmitter
   * */
  get<T = unknown>(name: string | number): EventEmitter<T> {
    if (this._events.find((e) => e.name === name)) {
      return (this._events.find((e) => e.name === name)?.value as unknown) as EventEmitter<T>;
    }

    const e = {
      name: name,
      value: new EventEmitter<T>()
    };

    this._events.push((e as unknown) as IEvent);

    return e.value;
  }

  removeAll() {
    this._events.forEach((e) => {
      if (!e.value.closed) {
        e.value.unsubscribe();
        e.value = (null as unknown) as EventEmitter<unknown>;
      }
    });
    this._events = [];
  }
}
