<div>
  <div class="ant-ui-widget-overlay"></div>
  <div class="ant-ajax-load">
    <div id="loading-spinner">
      <div class="ant-loading-wrapper">
        <div class="motif-spinner-heart">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
  <ng-content class="ant-ajax-text"></ng-content>
</div>
