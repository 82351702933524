import { ComponentFactory, ComponentFactoryResolver, Type } from '@angular/core';
import { ISelectorComponent } from '../interfaces/iSelectorCmp';

export interface IEntryComponentItems {
  [key: string]: { factory?: ComponentFactory<unknown>; component: Type<unknown>; priority: number };
}

/***
 *  Used to handle dependecies before application boot.
 *  Allow wait for multiple promises
 */
export class AppBootUtil {
  static entryComponents: IEntryComponentItems = {};
  static selectorComponents: Array<ISelectorComponent> = [];
  static isAotMode = false;
  static lazyModules: { [key: string]: boolean } = {};
  protected static _dependencies: Array<Promise<unknown>> = [];
  protected static _isDebugMode: boolean;

  static get isDebugMode(): boolean {
    if (typeof this._isDebugMode === 'undefined' && window.location.href.toLowerCase().indexOf('debug=true') >= 0) {
      this._isDebugMode = true;
    }

    return this._isDebugMode;
  }

  /**
   *  Register dependency promise
   *  @param dep - promise for registering
   */
  static registerDependency(dep: Promise<unknown>) {
    this._dependencies.push(dep);
  }

  /**
   *  Allow returning promise that listen for all the registered promises to be completed.
   *  return {Promise} - promise that waits for all internal registered promises
   */
  static waitOnAll(): Promise<unknown> {
    return Promise.all(this._dependencies);
  }

  static removeAll() {
    this._dependencies = [];
  }

  /**
   * register lazy module path, so this can be used to check if module is already loaded or not
   * @param modulePath lazy module path
   */
  static registerLazyModule(modulePath: string) {
    if (modulePath && typeof this.lazyModules[modulePath] === 'undefined') {
      this.lazyModules[modulePath] = true;
    }
  }

  /**
   * Register WCS widget definition component through EntryComponent decorator
   * @param name - name of the WCS widget definition that will be matched against data-widget-name
   * @param component - component class reference
   * @param priority - creation priority of the component. if multiple widgets ae found in the page from WCS, it will check which widget has the highests priority.
   */
  static registerEntryComponent(name: string, component: Type<unknown>, priority?: number) {
    if (name && typeof this.entryComponents[name] === 'undefined') {
      this.entryComponents[name] = {
        component: component,
        factory: undefined,
        priority: priority || 100
      };
    }
  }

  /**
   * this is used to register selector components, which can be defined with a given selector instead of using data-widget-name.
   * this should only be used when you want to tranclude content from WCS to the component
   * @param selector - component selector Ex: data-custom-component
   * @param component - component class reference
   * @param priority - creation priority of the component.
   * @param inputs - inputs available in component, these will be matched against the attributes passed through element from WCS.
   * @param replaceNode - should the component be created as child or replace the wcs element definition.
   */
  static registerSelectorComponent(selector: string, component: Type<unknown>, priority: number | undefined, inputs: Array<string> = [], replaceNode?: boolean) {
    if (selector) {
      this.selectorComponents.push({
        component: component,
        factory: undefined,
        inputs: inputs,
        priority: priority || 100,
        selector: selector,
        replaceNode: replaceNode
      });
    }
  }

  /**
   * Load all component factories for registered components through EntryComponent or SelectorComponent decorators
   * @param componentFactoryResolver
   */
  static loadEntryComponentFactories(componentFactoryResolver: ComponentFactoryResolver) {
    for (const key in this.entryComponents) {
      if (!this.entryComponents[key].factory) {
        try {
          const factory = componentFactoryResolver.resolveComponentFactory(this.entryComponents[key].component);
          this.entryComponents[key].factory = factory;
        } catch (e) {
          console.error(`entry component factory missing ${key} ${e}`);
        }
      }
    }

    this.selectorComponents.forEach((selectorComponent) => {
      if (!selectorComponent.factory) {
        try {
          const factory = componentFactoryResolver.resolveComponentFactory(selectorComponent.component);
          selectorComponent.factory = factory;
        } catch (e) {
          console.error(`selector component factory missing ${e}`);
        }
      }
    });
    this.selectorComponents.sort((a, b) => {
      if ((a.priority as number) < (b.priority as number)) {
        return -1;
      }
      if ((a.priority as number) > (b.priority as number)) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  static getSelectorComponentQuery() {
    let result = '';
    for (const key in this.selectorComponents) {
      result += key + ',';
    }

    return result.trimRight();
  }
}
