<section class="header-section">
  <div class="container">
    <div class="row">
      <div class="col-xxs-6 col-xs-6 col-sm-5 ant-logo-wrapper">
        <img src="assets/images/top-logo-abcbs.svg">
      </div>
    </div>
    <div class="row topnav">
      <div class="responsive" id="myTopnav">
        <div class="dropdown">
          <button class="dropbtn">My Plans
          </button>
          <div class="dropdown-content">
            <a href="#">Home</a>
            <a href="#">Medical</a>
            <a href="#">Pharmacy</a>
            <a href="#">ID Cards</a>
            <a href="#">Authorizations and Referrals</a>
          </div>
        </div>
        <div class="dropdown">
          <button class="dropbtn">Claims & Payments
            <i class="fa fa-caret-down"></i>
          </button>
          <div class="dropdown-content">
            <a href="#">Claims History</a>
            <a href="#">Submit a Claim</a>
            <a href="#">Pay With Bank Account Or Card</a>
          </div>
        </div>
        <div class="dropdown">
          <button class="dropbtn">Care
            <i class="fa fa-caret-down"></i>
          </button>
          <div class="dropdown-content">
            <a href="#">Find Care</a>
            <a href="#">Care Team</a>
          </div>
        </div>
        <div class="dropdown">
          <button class="dropbtn">My Health Dashboard
            <i class="fa fa-caret-down"></i>
          </button>
          <div class="dropdown-content">
            <a href="#">Dashboard</a>
            <a href="#">Action Plans</a>
            <a href="/dashboard/challenges/mhclink/partners/go/named/SydneyChallenges">Challenges</a>
            <a href="/dashboard/my-rewards/mhclink/partners/go/named/SydneyMyRewards">My Rewards</a>
            <a href="/dashboard/redeem-rewards/mhclink/partners/go/named/SydneyRedeemRewards">Redeem Rewards</a>
            <a href="/dashboard/redeem-rewards/mhclink/partners/go/named/TrackHealthData">Track Health Data</a>
            <a href="/dashboard/redeem-rewards/mhclink/partners/go/named/Wellbeing">Wellbeing</a>
          </div>
        </div>
        <div class="dropdown">
          <button class="dropbtn">Support
            <i class="fa fa-caret-down"></i>
          </button>
          <div class="dropdown-content">
            <a href="#">Dashboard</a>
            <a href="#">Action Plans</a>
            <a href="#">My Rewards</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
