import { Compiler, ComponentFactory, ComponentFactoryResolver, ComponentRef, Inject, Injectable, Injector, NgModuleFactory, NgModuleRef, Optional, ViewContainerRef } from '@angular/core';
import { WCS_ATTRIBUTES } from './../interfaces/iKeyAttribute';
import { ILazyModuleLoader } from './../interfaces/iLazyModuleLoader';
import { ISelectorComponent } from './../interfaces/iSelectorCmp';
import { AppBootUtil } from './appBootUtil';
import { AttributeHelper } from './attributeHlpr';
import { ContentHelper } from './contentHelper';

declare global{
  interface String {
    trimRight(char?: any): string;
    trimLeft(char?: any): string;
    toTitleCase(): string;
  }
}

/**
 * This service is used to create components on dynamic html.
 *  html needs to be set on an element.
 */
@Injectable()
export class DynamicHtmlUtility {
  createdComponents: Array<ComponentRef<unknown>> = [];
  createdComponents2: Array<ComponentRef<unknown>> = [];

  private _parser = new DOMParser();
  private ngModule: NgModuleRef<unknown>;

  constructor(
    private _injector: Injector,
    private _resolver: ComponentFactoryResolver,
    private _contHlpr: ContentHelper,
    private _attrHlpr: AttributeHelper,
    private _compiler: Compiler,
    @Optional()
    @Inject('LAZY_MODULE_LOADER')
    private _moduleLoader: ILazyModuleLoader,
    @Optional()
    @Inject('SHOW_WIDGET_FUNC')
    private _showWidgetFunc: Function
  ) {
    this.ngModule = _injector.get(NgModuleRef);
  }

  /**
   * this will parse the html in given html element and create components dynamically.
   * @param hostElement - html element that contains the dynamic html to parse
   * @param vcRef - view container reference to create component dynamically.
   */
  parseHtmlCreateComponents(hostElement: HTMLElement, vcRef: ViewContainerRef, extraEntryComponents: unknown = {}, extraSelectorComponents: unknown = {}): Promise<unknown> {
    return Promise.all([this.compileSelectorComponents(hostElement, vcRef), this.compileEntryComponents(hostElement, vcRef)]);
  }

  parseHtmlCreateEntryComponent(hostElement: HTMLElement, vcRef: ViewContainerRef, componentName: string, uniqueIdNumber: string = '') {
    return this.createEntryComponent(vcRef, hostElement, componentName, uniqueIdNumber);
  }

  compileEntryComponents(hostElement: HTMLElement, vcRef: ViewContainerRef) {
    const compPromises: Promise<void>[] = [Promise.resolve()];
    try {
      const widgetLoaderElements = hostElement.querySelectorAll('[data-widget-name]');
      for (const element of (widgetLoaderElements as unknown) as HTMLElement[]) {
        const widgetName = element.getAttribute('data-widget-name');
        const uniqueIdNumber = element.getAttribute('data-unique-id-number');
        const lazyModulePath = element.getAttribute('data-lazy-module-path');
        compPromises.push(this.createEntryComponent(vcRef, element, widgetName as string, uniqueIdNumber as string, lazyModulePath as string));
      }
    } catch (error) {
      console.error(error);
    }

    return Promise.all(compPromises);
  }

  compileSelectorComponents(hostElement: HTMLElement, vcRef: ViewContainerRef) {
    const compPromises: Promise<void>[] = [Promise.resolve()];
    try {
      AppBootUtil.selectorComponents.forEach((selectorComponent) => {
        const selElements = hostElement.querySelectorAll(selectorComponent.selector);
        for (const element of (selElements as unknown) as HTMLElement[]) {
          compPromises.push(this.createSelectorComponent(selectorComponent, vcRef, element));
        }
      });
    } catch (error) {
      console.error(error);
    }

    return Promise.all(compPromises);
  }

  destroyComponents() {
    this.createdComponents.forEach((comp) => comp.destroy());
    this.createdComponents = [];
    this.createdComponents2.forEach((comp) => comp.destroy());
    this.createdComponents2 = [];
  }

  runCheckOnComponents() {
    this.createdComponents2.forEach((comp) => comp.changeDetectorRef.detectChanges());
  }

  parseDocumentTemplates(template: string): string {
    const parsedDoc = this._parser.parseFromString(template, 'text/html');
    const temp = parsedDoc.querySelectorAll('[type="application/json"]');
    for (let index = 0; index < temp.length; index++) {
      const t = <HTMLElement>temp[index];
      let widgetName = t.parentElement?.getAttribute('data-widget-name');
      const uniqueId = t.parentElement?.getAttribute('data-unique-id-number');

      if (uniqueId) {
        widgetName = widgetName + '-' + uniqueId;
      }

      if (widgetName) {
        this._contHlpr.registerContent(widgetName, t.innerText);
      }

      t.innerHTML = '';
    }

    return parsedDoc.body.innerHTML;
  }

  loadLazyModule(path: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this._moduleLoader.customLoad(path).then(
        (t) => {
          //if module is already loaded
          if (typeof t === 'undefined') {
            resolve();
            return;
          }

          if (t instanceof NgModuleFactory) {
            t.create(this.ngModule.injector);
            resolve();
          } else {
            this._compiler.compileModuleAndAllComponentsAsync(t).then((m) => {
              resolve();
            });
          }
        },
        (e) => {
          reject(e);
        }
      );
    });
  }

  protected createSelectorComponent(selectorComponent: ISelectorComponent, vcRef: ViewContainerRef, element: HTMLElement): Promise<void> {
    return new Promise((resolve) => {
      try {
        const inj = Injector.create({
          providers: [
            {
              provide: WCS_ATTRIBUTES,
              useValue: this._attrHlpr.getAttributes(element)
            }
          ],
          parent: vcRef.injector
        });
        let compRef: ComponentRef<unknown> | undefined;
        if (selectorComponent.replaceNode) {
          compRef = vcRef.createComponent(selectorComponent.factory as ComponentFactory<unknown>, undefined, inj, [Array.prototype.slice.call(element.childNodes)]);
          selectorComponent.inputs?.forEach((input) => {
            const attr = element.getAttribute(`${input}`) || element.getAttribute(`[${input}]`);
            if (attr && typeof (compRef?.instance as { [k: string]: string | boolean })[input] !== 'undefined') {
              // tslint:disable-next-line:quotemark
              (compRef?.instance as { [k: string]: string | boolean })[input] = attr === 'true' ? true : attr === 'false' ? false : attr.trimLeft("'").trimRight("'");
              compRef?.location.nativeElement.setAttribute(`${input}`, (compRef.instance as { [k: string]: string | boolean })[input]);
            }
          });
          if ((compRef.instance as { [k: string]: string | boolean }).manualInit) {
            (compRef.instance as { [k: string]: () => void }).manualInit();
          }
          element.parentNode?.replaceChild(compRef.location.nativeElement, element);
        } else {
          compRef = selectorComponent.factory?.create(inj, [Array.prototype.slice.call(element.childNodes)], element);
        }
        this.createdComponents2.push(compRef as ComponentRef<unknown>);
      } catch (error) {
        console.error(error);
      }
      resolve();
    });
  }

  protected createEntryComponent(vcRef: ViewContainerRef, element: HTMLElement, widgetName: string, uniqueIdNumber: string = '', lazyModulePath?: string) {
    return new Promise<void>((resolve) => {
      let p: Promise<void>;
      if (this._moduleLoader && lazyModulePath && typeof AppBootUtil.entryComponents[widgetName] === 'undefined') {
        p = this.loadLazyModule(lazyModulePath);
      } else {
        p = Promise.resolve();
      }

      p.then(
        () => {
          this.tryCreateEntryComponent(uniqueIdNumber, widgetName, element, vcRef);
          resolve();
        },
        (e) => {
          console.error(e);
          console.error(`failed loading lazy module path ${lazyModulePath} for widget ${widgetName}`);
          this.tryCreateEntryComponent(uniqueIdNumber, widgetName, element, vcRef);
          resolve();
        }
      );
    });
  }

  private tryCreateEntryComponent(uniqueIdNumber: string, widgetName: string, element: HTMLElement, vcRef: ViewContainerRef) {
    const widgetId = uniqueIdNumber ? widgetName + '-' + uniqueIdNumber : widgetName;

    if (typeof AppBootUtil.entryComponents[widgetName] !== 'undefined') {
      try {
        const attrs = this._attrHlpr.getAttributes(element);
        if (this._showWidgetFunc && !this._showWidgetFunc(attrs)) {
          return;
        }

        const inj = Injector.create({
          providers: [
            {
              provide: WCS_ATTRIBUTES,
              useValue: attrs
            }
          ],
          parent: vcRef.injector
        });
        const compRef = vcRef.createComponent(AppBootUtil.entryComponents[widgetName].factory as ComponentFactory<unknown>, undefined, inj, []);
        if (uniqueIdNumber) {
          (compRef.instance as { setUniqueIdJsonContent: (t: string) => void }).setUniqueIdJsonContent(widgetId);
        }

        // TODO: remove after issue https://github.com/angular/angular/pull/34450 is fixed in angular v9
        if (
          AppBootUtil.entryComponents[widgetName].factory &&
          ((AppBootUtil.entryComponents[widgetName].factory as unknown) as { componentDef: { selectors: {}[] } }).componentDef &&
          ((AppBootUtil.entryComponents[widgetName].factory as unknown) as { componentDef: { selectors: {}[] } }).componentDef.selectors &&
          ((AppBootUtil.entryComponents[widgetName].factory as unknown) as { componentDef: { selectors: {}[][] } }).componentDef.selectors[0].length === 3
        ) {
          const selector = ((AppBootUtil.entryComponents[widgetName].factory as unknown) as { componentDef: { selectors: {}[][] } }).componentDef.selectors[0][1];
          if (!compRef.location.nativeElement.hasAttribute(selector)) {
            compRef.location.nativeElement.setAttribute(selector, '');
          }
        }

        element.parentNode?.replaceChild(compRef.location.nativeElement, element);
        this.createdComponents.push(compRef);
      } catch (e) {
        console.error(`${widgetName} creation error.`);
        console.error(e);
      }
    } else {
      console.error(`${widgetName} not defined properly with EntryComponent decorator.`);
    }
  }
}
