import {AfterViewInit, Component} from '@angular/core';
import {MHCSDKService} from "@mhc/sdk";
import {Router} from "@angular/router";

@Component({
  selector: 'left-cont-cmp',
  templateUrl: './leftContCmp.html',
  styleUrls: ['./leftContCmp.scss']
})
export class LeftContainerComponent implements AfterViewInit {
  menuOpen: boolean = false;
  dashboard: string = 'dashboard/';

  content = {
    links : [
        {
          "url": "my-health-dashboard",
          "label": "Dashboard",
          "id": "leftNavDashboardMyHealth",
          "selected": false,
        },
        {
          "url": "manage-my-health",
          "label": "Manage My Health",
          "id": "leftNavManageMyHealth",
          // "type": "generic-lazy",
          "content": "Manage My Health",
          "target": "",
          "featureSet": "CLINICAL",
          "feature": "CLINICALQUESTIONNAIRE"
        },
        {
          "url": "activity-tracking",
          "label": "Activity Tracking",
          "id": "leftNavActivityTrackingMyHealth",
          "rule": {
            "and": [
              {
                "in": [
                  "HAS_MEMBER_ENGAGE_V2_FEATURES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              }
            ]
          }
        },
        {
          "url": "action-plan",
          "label": "Action Plans",
          "id": "leftNavActionPlansMyHealth",
          "rule": {
            "and": [
              {
                "in": [
                  "HAS_MEMBER_ENGAGE_V2_FEATURES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              }
            ]
          }
        },
        {
          "url": "challenges/mhclink/partners/go/named/SydneyChallenges",
          "label": "Challenges",
          "id": "leftNavMyRewardsMyHealth",
          "rule": {
            "or": [
              {
                "in": [
                  "REWARDS",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              },
              {
                "in": [
                  "INCENTIVES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              },
              {
                "in": [
                  "CUSTOM_INCENTIVES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              }
            ]
          }
        },        {
          "url": "my-rewards/mhclink/partners/go/named/SydneyMyRewards",
          "label": "My Rewards",
          "id": "leftNavMyRewardsMyHealth",
          "rule": {
            "or": [
              {
                "in": [
                  "REWARDS",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              },
              {
                "in": [
                  "INCENTIVES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              },
              {
                "in": [
                  "CUSTOM_INCENTIVES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              }
            ]
          }
        },        {
          "url": "redeem-rewards/mhclink/partners/go/named/SydneyRedeemRewards",
          "label": "Redeem Rewards",
          "id": "leftNavMyRewardsMyHealth",
          "rule": {
            "or": [
              {
                "in": [
                  "REWARDS",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              },
              {
                "in": [
                  "INCENTIVES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              },
              {
                "in": [
                  "CUSTOM_INCENTIVES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              }
            ]
          }
        },
      {
          "url": "redeem-rewards/mhclink/partners/go/named/TrackHealthData",
          "label": "Track Health Data",
          "id": "leftNavTrackHealthDataMyHealth",
          "rule": {
            "or": [
              {
                "in": [
                  "REWARDS",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              },
              {
                "in": [
                  "INCENTIVES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              },
              {
                "in": [
                  "CUSTOM_INCENTIVES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              }
            ]
          }
        },      {
          "url": "redeem-rewards/mhclink/partners/go/named/Wellbeing",
          "label": "Wellbeing",
          "id": "leftNavWellbeingMyHealth",
          "rule": {
            "or": [
              {
                "in": [
                  "REWARDS",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              },
              {
                "in": [
                  "INCENTIVES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              },
              {
                "in": [
                  "CUSTOM_INCENTIVES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              }
            ]
          }
        },
        {
          "url": "achievements",
          "label": "Badges",
          "id": "leftNavAchievementsMyHealth",
          "rule": {
            "and": [
              {
                "in": [
                  "HAS_MEMBER_ENGAGE_V2_FEATURES",
                  {
                    "var": [
                      "features",
                      "EMPTY"
                    ]
                  }
                ]
              }
            ]
          }
        },
        {
          "url": "programs",
          "label": "Programs",
          "id": "leftNavProgramsMyHealth"
        },
        {
          "url": "my-health-dashboard/my-health-news/foryou",
          "label": "My Health News",
          "id": "leftNavTopicsMyHealth",
          "type": "widget",
          "submenu": [
            {
              "label": "COVID-19",
              "url": "my-health-dashboard/my-health-news/covid-19",
              "analyticsText": "covid19MenuHealthNews"
            },
            {
              "label": "For You",
              "url": "my-health-dashboard/my-health-news/for-you-recommended",
              "analyticsText": "forYouMenuHealthNews"
            },
            {
              "label": "Around-Web",
              "url": "my-health-dashboard/my-health-news/around-web",
              "analyticsText": "AroundWebMenuHealthNews"
            },
            {
              "label": "Saved",
              "url": "my-health-dashboard/my-health-news/saved-topics",
              "analyticsText": "savedMenuHealthNews"
            }
          ]
        }
      ]
    };

    constructor(private sdkService: MHCSDKService, private router: Router) {
    }

    ngAfterViewInit() {
      setTimeout(() => this.hightlightUrl());
    }
  
    hightlightUrl() {
      const url = window.location.href.toLowerCase()?.split('/');
      const path = url[url.length-1];
      for (const menu in this.content?.links) {       
        if (this.content.links[menu]?.url?.toLowerCase() === path) {
          this.content.links[menu].selected = true;
          break;
        }
      }
    }

    public toggleSideMenu() {
      this.menuOpen = !this.menuOpen;
      const leftNavEle = document.querySelector('.left-side-container');
      const backOvrly = document.querySelector('.background-overlay');
      const sideNavMob = document.querySelector('.side-nav-mobile-toggle');
  
      if (this.menuOpen) {
        leftNavEle && leftNavEle.classList.remove('left-nav-closed');
        leftNavEle && leftNavEle.classList.add('left-nav-opened');
        backOvrly && backOvrly.classList.remove('hidden');
        sideNavMob && sideNavMob.classList.add('hide');
      } else {
        leftNavEle && leftNavEle.classList.remove('left-nav-opened');
        leftNavEle && leftNavEle.classList.add('left-nav-closed');
        backOvrly && backOvrly.classList.add('hidden');
        setTimeout(() => {
          sideNavMob && sideNavMob.classList.remove('hide');
        }, 350);
      }
    }

    logout() {
      this.sdkService.deAuthenticate().then(() => {
        this.router.navigateByUrl('/');
      });
    }
}
