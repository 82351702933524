import { Injectable } from '@angular/core';
import { Cache } from '../../core/public_api';
import { IHttpRequest } from '../interfaces/iHttpRequest';
import { IHttpResponse } from '../interfaces/iHttpResponse';
import { UrlHelper } from './urlHelper';

@Injectable()
export class HttpCacheHandler {
  constructor(private _urlHelper: UrlHelper, private _cache: Cache) {}

  lookupCache(requestOptions: IHttpRequest) {
    let cachedResp!: IHttpResponse;
    //app in-memory cache
    if (requestOptions.cacheable && !requestOptions.clearCache) {
      cachedResp = this._cache.get(requestOptions.cacheKey || this._urlHelper.getCacheableUrl(requestOptions.finalUrl as string, false)) as IHttpResponse;
    }

    return cachedResp;
  }

  storeInMemoryCache(requestOptions: IHttpRequest, finalResp: IHttpResponse) {
    if (requestOptions.cacheable) {
      this._cache.put(requestOptions.cacheKey || this._urlHelper.getCacheableUrl(requestOptions.finalUrl as string, false), finalResp);
    }
  }

  clearCacheItems(keyRegexStr: string) {
    this._cache.removeByRegexString(keyRegexStr);
  }
}
