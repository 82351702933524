import { Injectable } from '@angular/core';

@Injectable()
export class TitleService {
    content = {
        title:{
          'dashboard':'My Health Dashboard',
          'my-health-dashboard':'My Health Dashboard',
          'manage-my-health':'Manage My Health',
          'activity-tracking':'Activity Tracking',
          'action-plan':'Action Plans',
          'anthem-custom-rewards':'Anthem Custom Rewards',
          'programs': 'Programs',
          'achievements':'Achievements',
          'my-rewards':'My Rewards',
          'challenges':'Challenges',
          'redeem-rewards':'Redeem Rewards',
        }
      }
    
      get title(){
        const url = window.location.href.toLowerCase()?.split('/');
        const path = url[url.length > 4 ? 4 : 3];
        return this.content?.title[path];
      }
}
