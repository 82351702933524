import { Injectable } from '@angular/core';
import * as metadata from '../interfaces/iMetadata';
import { IAppSession } from './../interfaces/iAppSession';

/**
 *  Acts as app session
 */
@Injectable()
export class AppSession implements IAppSession {
  metaData = ({
    brandCd: 'ABCBS',
    firstNm: 'John'
  } as unknown) as Omit<metadata.IMetadata, 'featureFlags'>;

  wcsHash: string = 'wcsHash';
  templateHash: string = '';
  isDebugMode: boolean = false;
  pageTitle: string = '';
  pageName: string = '';
  isIframeMode = false;
  iframeData = {
    urls: {} as string,
    contractUid: ''
  };

  serverTime!: Date;
  timeStamp: Date = ({} as unknown) as Date;
  //DO NOT add any new properties or functions to this class.
  //add the properties to tcpAppSession file and access it like (<any>this._appSession).newProp

  /**
   *  contruct logout url using hostname or brand combination
   *
   */
  getLogoutUrl(): string | undefined {
    return undefined;
  }
}
