import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, StaticProvider, Type, ViewContainerRef } from '@angular/core';
import { getSafeError } from '../values/safeError';

/**
 * Components helper class to easily work with
 * allows to:
 * - get application root view container ref
 */
@Injectable()
export class ComponentHelper {
  root!: ViewContainerRef;

  constructor(protected applicationRef: ApplicationRef, protected componentFactoryResolver: ComponentFactoryResolver, protected injector: Injector) {}

  getDocument(): Document {
    return this.injector.get(DOCUMENT);
  }

  /**
   * In some cases, like using ngUpgrate,
   * you need to explicitly set view container ref
   * to made this method working you need to add:
   * ```typescript
   *  @Component({
   *   selector: 'my-app',
   *   ...
   *   })
   *  export class MyApp {
   *    constructor(componentsHelper:ComponentsHelper, viewContainerRef: ViewContainerRef) {
   *        // A Default view container ref, usually the app root container ref.
   *        // Has to be set manually until we can find a way to get it automatically.
   *        componentsHelper.setRootViewContainerRef(viewContainerRef)
   *      }
   *  }
   * ```
   */

  setRootViewContainerRef(value: ViewContainerRef): void {
    this.root = value;
  }

  /**
   * This is a name conventional class to get application root view component ref
   * @returns - application root view component ref
   */

  getRootViewContainerRef(): ViewContainerRef {
    // https://github.com/angular/angular/issues/9293
    if (this.root) {
      return this.root;
    }

    const comps = this.applicationRef.components;

    if (!comps.length) {
      throw getSafeError(`ApplicationRef instance not found`);
    }

    try {
      /* one more ugly hack, read issue above for details */
      const rootComponent = ((this.applicationRef as unknown) as {
        _rootComponents: {
          _hostElement: {
            vcRef: ViewContainerRef;
          };
        }[];
      })._rootComponents[0];
      this.root = rootComponent._hostElement.vcRef;
      return this.root;
    } catch (e) {
      throw getSafeError(`ApplicationRef instance not found`);
    }
  }

  /**
   * Creates an instance of a Component and attaches it to the View Container found at the
   * `location` specified as {@link ViewContainerRef}.
   *
   * You can optionally provide `providers` to configure the {@link Injector} provisioned for this
   * Component Instance.
   *
   * Returns {@link ComponentRef} representing the newly created Component.
   * @param - @Component class
   * @param - reference to the location
   * @param - optional array of providers
   * @returns - returns ComponentRef<T>
   */

  appendNextToLocation<T>(componentClass: Type<T>, location: ViewContainerRef, providers?: StaticProvider[]): ComponentRef<T> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const parentInjector = location.injector;
    let childInjector: Injector = parentInjector;
    if (providers && providers.length > 0) {
      childInjector = Injector.create({ providers: providers, parent: parentInjector });
    }

    return location.createComponent(componentFactory, location.length, childInjector);
  }

  /**
   * Helper methods to add ComponentClass(like modal backdrop) with options
   * of type ComponentOptionsClass to element next to application root
   * or next to provided instance of view container
   * @param - @Component class
   * @param - options class
   * @param - instance of options
   * @returns - returns ComponentRef<T>
   */

  appendNextToRoot<T>(componentClass: Type<T>, componentOptionsClass: unknown, options: unknown): ComponentRef<T> {
    const location = this.getRootViewContainerRef();
    return this.appendNextToLocation(componentClass, location, [{ provide: componentOptionsClass, useValue: options }]);
  }
}
