import { ElementRef, Injectable } from '@angular/core';
import { BROWSER_TYPE } from './../values/browserType';

/**
 *  Helper provider to handle various element level functions.
 */
@Injectable()
export class ElementHelper {
  static reflow(element: HTMLElement): void {
    new Function('bs', 'return bs')(element.offsetHeight);
  }

  // source: https://github.com/jquery/jquery/blob/master/src/css/var/getStyles.js

  static getStyles(elem: HTMLElement) {
    // Support: IE <=11 only, Firefox <=30 (#15098, #14150)
    // IE throws on elements created in popups
    // FF meanwhile throws on frame elements through "defaultView.getComputedStyle"
    let view = elem.ownerDocument.defaultView;

    if (!view || !view.opener) {
      view = window;
    }

    return view.getComputedStyle(elem);
  }

  /**
   *  Gets HTML node name for given element.
   *  @param element - native element
   *  @return - return node name
   */
  static nodeName(element: HTMLElement | HTMLElement[] = {} as HTMLElement): string {
    return ((element as HTMLElement).nodeName || ((element as HTMLElement[]).length && (element as HTMLElement[])[0] && (element as HTMLElement[])[0].nodeName) || '').toLowerCase();
  }

  /**
   *  Set focus to an element
   *  @param element - element ref
   *  @param querySelector - css based query selector to be used to find internal element
   *  @param delay - allow delay on focus, default uses 100ms.
   */
  setFocus(element: ElementRef<HTMLElement>, querySelector: string, delay: number = 100) {
    setTimeout(() => {
      const ele = this.getElement(element, querySelector);
      if (ele) {
        (ele as { focus: () => void }).focus();
        if (BROWSER_TYPE.isSafariMobile && typeof (ele as { scrollIntoView: () => void }).scrollIntoView !== 'undefined') {
          (ele as { scrollIntoView: (t: boolean) => void }).scrollIntoView(false);
        }
      }
    }, delay);
  }

  remove(element: ElementRef<unknown>, querySelector: string) {
    const ele = this.getElement(element, querySelector);
    (ele as { parentNode: HTMLElement }).parentNode.removeChild(ele as HTMLElement);
  }

  /**
   *  Allow getting internal element from css query selector
   *  @param element - element ref
   *  @param querySelector - css based query selector to be used to find internal element
   *  @return - element
   */
  protected getElement(element: ElementRef<unknown>, querySelector: string) {
    if (element && element.nativeElement && querySelector) {
      return (element.nativeElement as HTMLElement).querySelector(querySelector);
    } else if (element && element.nativeElement) {
      return element.nativeElement;
    }
  }
}
