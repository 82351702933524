<link
rel="stylesheet"
type="text/css"
href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
/>

<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="footer-col">
                <ul>
                    <li><a href="#">About</a></li>
                    <li><a href="#">Legal</a></li>
                    <li><a href="#">Privacy</a></li>
                    <li><a href="#">Accessibility</a></li>
                    <li><a href="#">Nondiscrimination Notice</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <ul>
                    <li><a href="#" target="_blank">Press Room</a></li>
                    <li><a href="#" target="_blank">Careers</a></li>
                    <li><a href="#" target="_blank">Anthem Foundation</a></li>
                </ul>
            </div>
            <div class="footer-col">
                <h4>follow us</h4>
                <div class="social-links">
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                    <a href="#"><i class="fab fa-youtube"></i></a>
                </div>
            </div>
            <div class="button-group display-none-mobile">
                <div id="footerBtnDdl" class="language-selector">
                    <button type="button" class="btn dropdown-toggle btn-secondary-dk-alt" id="psButtonddl-lng" aria-expanded="false">
                        <span class="buttonMain">Select a Language </span>
                        <span class="fa fa-caret-down"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-content">
        <p>© 2020 copyright of Anthem Insurance Companies, Inc. Serving Colorado, Connecticut, Georgia, Indiana, Kentucky, Maine, Missouri (excluding 30 counties in the Kansas City area), Nevada, New Hampshire, Ohio, Virginia (excluding the Northern Virginia suburbs of Washington, D.C.), and Wisconsin. Independent licensees of the Blue Cross and Blue Shield Association.</p>
    </div>
</footer>
