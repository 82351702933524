import {Component, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import {loginApi} from './loginApi';
import { Router} from '@angular/router';
import { NavigationHelper } from 'src/libs/utilities/services/navigationHelper';
import {AuthService, BackendService} from "@mhc/sdk/src/lib/core-services";
import {MHCSDKService} from "@mhc/sdk";
import {Subject} from "rxjs";
@Component({
  selector: 'app-login',
  templateUrl: './loginCmp.html',
  styleUrls: ['./loginCmp.scss']
})
export class LoginComponent implements OnInit {
  constructor(private service:loginApi, private _navigationHelper: NavigationHelper, private auth: AuthService, private sdk: MHCSDKService,
              private backend: BackendService){

  }
  @ViewChild('loginForm', { static: false }) public loginForm: NgForm;
  username:string;
  password:string;
  hostname:string;
  showValidations:boolean = false;
  loggedIn = false;
  loginFailure = false;

  ngOnInit() {
    this.hostname = this.backend.getLoginServerUrl();
    if (this.hostname.startsWith('https://')) {
      this.hostname = this.hostname.slice('https://'.length);
    }
  }

  submitClick(){
    if(this.loginForm.invalid){    
      this.showValidations = true;  
    } else {
      this.sdk.setOptions({hostname: this.hostname});
      this.loginFailure = false;
      this.loggedIn = false;
      this.auth.login(this.username, this.password).subscribe((success) => {
        if (success) {
          this.auth.shardLogin(this.auth.username, this.auth.token, this.auth.timestamp).subscribe((loggedIn) => {
            const authenticated$ = this.sdk._authenticated as Subject<boolean>;
            authenticated$.next(true);
            this.loggedIn = true;
            this._navigationHelper.navigateTo('dashboard');
          });
        } else {
          this.loginFailure = true;
        }
      });
    }
  }

  skipLogin() {
    this._navigationHelper.navigateTo('dashboard');
    // this._route.navigateByUrl('dashboard');
  }
}
